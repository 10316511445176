*{
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}
body{
  /* box-sizing: border-box; */
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light.services_p {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }







.check2{
  max-width: 1440px;
}
.my_navbar{
    background-color: white;
    border-bottom: 1px solid #dedede;
    padding-right: 135px;
    padding-left: 90px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.content{
    font-size: 12px;
    display: flex;
    justify-content: end;
    
    
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: rgb(252, 59, 110);
}
.navbar-nav .nav-link:hover {
  color: rgb(252, 59, 110);
}
.dropdown-menu{
  font-size: 14px;
    font-weight: 300;
    color: #777777;
}
.logo-container{
  padding-bottom: 5px;
}

@media (max-width: 600px) {
  .my_navbar {
      padding-right: 15px; /* Reduce padding for smaller screens */
      padding-left: 15px; /* Add padding-left for small screens */
      background-color: white;
    
  }
  .logo {
      padding-left: 0; /* Align logo to the left end */
  }
  .nav_btn {
      margin-left: auto; /* Push the toggle button to the right */
  }
  .navbar-nav{
    padding-top: 30px;
    margin: 0 auto;
  }
  .content{
    justify-content: center;

 
  }
}


.hero_img{
    background-image: url("./pics/header-bg.jpg.webp");
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 600px;
}
.hero_p1{
    font-size: 18px;
}
.hero_h1{
    font-size: 60px;
    font-weight: bolder;
}
.hero_p2{
    font-size: 14px;
    font-weight: 300;
    color: #777777;
}
.hero_content{
  position: absolute;
  top: 200px;
  padding-left: 40px;   
}
.hero_btn{
    background-color: rgb(252, 59, 110);
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
}
.hero_btn:hover {
  background-color: rgb(252, 59, 110);
  color: white; /* Keep the color unchanged */
}




.services_section{
    margin-top: 100px;
    margin-bottom: 100px;
}
.services_2{
  padding-left: 50px;
}
.services_3{
  padding-left: 50px;
}
.services_h{
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}
.services_p{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #777777;
}



.fitness_main{
  margin-top: 100px;
}
.testimonial_bg{
    margin-top: 100px;
    margin-bottom: 100px;
    background-image: url("./pics/testimonial-bg.jpg.webp");
    height: auto;
    background-size: cover;
}
.testimonial_overlay{
    height: auto;
    background-color: rgb(5, 190, 240, 0.9);
}
.testimonial_h1{
    font-size: 36px;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 600;
}
.testimonial_p{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: white;
}
.testimonial_row2{
    padding-top: 80px;
    padding-bottom: 80px;
    
}
.testimonial_card2{
    width: 50%;
}
.testimonial_h4{
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: black;
    font-weight: 600;
}
.testimonial_card_p{
  font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #777777;
}
.testimonial_card1{
  width: 50%;
}

.my_card
{
  display: flex;
  gap: 20px;
}

.abc_body{
  margin-top: 100px;
  margin-bottom: 100px;
}
.abc_card{
  height: auto;
  padding: 30px;
  border: none;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}
.abc_right{
  padding-left: 80px;
}
.abc_h4{
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-weight: 600;
  margin-top: 60px;
}
.abc_p{
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #777777;
}





.pro_col{
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.pro_body{
  margin-top: 100px;
  margin-bottom: 100px;
}
.pro_h1{
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  color: rgb(5, 190, 240);
  font-weight: 600;
}
.pro_p{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #777777;
}



.fitness2_main{
  margin-top: 100px;
}
.testimonial2-section{
  max-width: 1440px;
  margin: 0 auto;
}
.testimonial2_bg{
    margin-top: 100px;
    background-image: url("./pics/service-bg.jpg.webp");
    height: auto;
    background-size: cover;
}
.testimonial2_overlay{
    height: auto;
    background-color: rgb(0, 0, 0, 0.7);
}
.testimonial2_h1{
    font-size: 36px;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 600;
}
.testimonial2_h4{
  font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 600;
}
.testimonial2_p{
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: white;
}
.testimonial2_sec{
  padding-left: 100px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.testimonial_box{
  padding: 30px;
}
.testimonial_box:hover{
  background: rgb(252, 59, 110);
  box-shadow: -14.142px 14.142px 20px 0px rgba(157, 157, 157, 0.5);
    cursor: pointer;
}


.customer-section{
  max-width: 1440px;
  margin: 0 auto;
}
.cs_left{
  background-image: url("./pics/info-img.jpg.webp");
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}
.cs_right{
  padding-top: 150px;
}
.cs_h1{
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-weight: 600;  
}
.cs_p{
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #777777;
  margin-top: 20px;  
}
.cs_btn{
  background-color: rgb(252, 59, 110);
  line-height: 36px;
  border-radius: 25px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  display: inline-block;
  font-weight: 500;
  outline: none;
  text-decoration: none;
}
.cs_btn:hover {
  background: rgb(252, 59, 110); /* Maintain the original background */
  color: white; /* Keep the color unchanged */
}
.cs_icon{
  margin-left: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
}


.architecture-section{
  max-width: 1440px;
  margin: 0 auto;
}
.as_left{
  padding-top: 100px;
}
.as_h1{
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-weight: 600;
  padding-bottom: 30px;
}
.as_p{
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #777777;
  padding-bottom: 15px;
}
.as_right{
  background-image: url("./pics/video-bg.jpg.webp");
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
}
.as_over_lay{
  height: 600px;
  background-color: rgb(5, 190, 240, 0.7);
}
.play_img{
  position: relative;
  top: 45%;
  left: 45%;
}




.ps_row{
  margin-top: 100px;
  margin-bottom: 100px;
}





.fs_h1{
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  color: black;
  font-weight: 600;
}
.fs_p{
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: rgb(252, 62, 110);
  cursor: pointer;
  text-decoration: none;
}
#name,#email,#subject{
  padding: 10px;
  margin: 10px;
  width: 100%;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  outline: none;
}
#message{
  width: 100%;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  outline: none;
  
}

.fs_row2{
  padding-right: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.fs_form_left{
  padding: 10px;
}
.fs_form_right{
  padding: 20px;
}
  
.contact_row{
  padding-top: 50px;
  padding-bottom: 100px;
}

.footer-section{
  max-width: 1440px;
  margin: 0 auto;
}
.f_h4{
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 600;
}
.f_p{
  padding-top: 20px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #777777;
}
.f_row1{
  padding-left: 90px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.f_p2{
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: rgb(5, 190, 240);
  font-weight: 600;
  line-height: 30px;
}
.last_span{
  color: rgb(252, 59, 110);
}
.f_contact{
  padding-left: 50px;
}
.f_news{
  padding-left: 50px;
  padding-right: 100px;
}
.last_mail{
  border-radius: 25px;
  border: none;
  padding-top: 8px;
  padding-left: 15px;
  padding-bottom: 5px;
  width: 80%;
  
}
.f_row2{
  padding-left: 90px;
  padding-bottom: 100px;
}
.f_p_l{
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #777777;
}
.f_icon {
  font-size: 2rem; /* Adjust font size */
  color: white;
}
.f_icon:hover{
  color: rgb(5, 190, 240);
}


@media (max-width: 768px) {
  .logo{
    padding-left: 0;
  }


    .hero_img{
        background-image: none;
        background-position: center center;
        background-size: cover;
        padding-left: 12px;
        padding-right: 12px;
    }
    .hero_p1{
        font-size: 14px;
    }
    .hero_h1{
        font-size: 40px;
        font-weight: bolder;
    }
    .hero_content{
      position: absolute;
      top: 100px;
      padding-left: 12px;
      padding-right: 20px;   
    }
    .hero_btn{
        border-radius: 20px;
        margin-top: 20px;
        margin-left: 10px;
        padding-left: 30px;
        padding-right: 30px;
        color: white;
    }
    .services_section{
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 50px;
    }      
    .services_1,.services_2,.services_3{
        padding-left: 0;
    }
    .testimonial2_sec{
      padding-left: 20px;
      padding-right: 20px;
    }
    .testimonial_card2{
      margin-left: 0;
    }
    .my_card,.card1_div,.card2_div{
      flex-direction: column;
    }
    .testimonial_card1,.testimonial_card2{
      width: 100%;
    }

    .abc_left,.abc_right{
      padding-left: 30px;
      padding-right: 30px;
    }

    .cs_right{
      padding-top: 50px;
    }
    .as_left{
      padding-top: 50px;
    }
    .fs_row2{
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .ps_row{
      flex-direction: column;
    }
    #name,#email,#subject{
      width: 80%;
    }
    #message{
      width: 80%;
    }

    .f_row1{
      margin: 0 auto;
      padding-right: 0;
      padding-left: 30px;
    }
    .f_about,.f_contact,.f_news{
      padding-left: 0;
    }
    .f_row2{
      margin: 0 auto;
      padding-left: 20px;
    }
}
